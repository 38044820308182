import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {

    const location = useLocation();

    function navToggleClick() {
        const sidebar = document.getElementById('sidebar');
        if (sidebar) {
            sidebar.classList.remove('hide-sidebar');
        }
    }

    return (
        <div className="header">

            <div className="top-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                            <div className="contact_info_wrapper">
                                <ul>
                                    <li><i className="fa fa-envelope"></i>info@alshifamedicalsupplies.ae</li>
                                    <li className="contact_number_wrapper hidden-xs"><i className="fa fa-phone"></i>+971 50 580 3883</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                            <div className="signin_and_social_icon_wrapper">
                                <ul>
                                    <li className="social_icon_wrapper hidden-xs">
                                        <ul>
                                            {/* <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                            <li><a href="#"><i className="fa fa-linkedin"></i></a></li> */}
                                        </ul>
                                    </li>
                                    {/* <li className="dropdown signin_wrapper">
                                        <i className="fa fa-sign-in"></i> Login / Register
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main_menu_wrapper hidden-xs hidden-sm">
                <nav className="navbar mega-menu navbar-default">
                    <div className="container">
                        <div className="navbar-header hidden-xs hidden-sm">
                            <Link to="/" className="home-page"><img src="images/AL-SHIFA-MEDICAL-SUPPLIES-LOGO.jpg" alt="" style={{ width: "10%", position: "absolute", bottom: "0px" }} /></Link>
                        </div>
                        <div className="collapse navbar-collapse">
                            <ul className="nav navbar-nav navbar-right">
                                <li className='active dropdown menu-highlight'>
                                    <Link to="/" className={location.pathname === "/" ? "home-page menu-highlight" : "home-page"}>Home</Link>
                                </li>
                                <li className="active dropdown">
                                    <Link to="/about" className={location.pathname === "/about" ? "about-page menu-highlight" : "about-page"}>About</Link>
                                </li>
                                <li className="active dropdown">
                                    <Link to="/services" className={location.pathname === "/services" ? "services-page menu-highlight" : "services-page"}>Services</Link>
                                </li>
                                <li className="active dropdown">
                                    <Link to="/contact" className={location.pathname === "/contact" ? "contact-page menu-highlight" : "contact-page"}>Contact</Link>
                                </li>
                                {/* <li className="dropdown shop_dropdown_wrapper">
                                    User
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link to="/user-list" className="">User List</Link>
                                            <Link to="/add-user" className="">Add User</Link>
                                        </li>
                                    </ul>
                                </li> */}
                            </ul>

                        </div>
                    </div>
                </nav>
            </div>
            <div className="mobail_menu_main visible-xs visible-sm">
                <div className="navbar-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                                <Link to="/" className="home-page"><img src="images/AL-SHIFA-MEDICAL-SUPPLIES-LOGO.jpg" alt="" style={{ width: "50%", position: 'relative', top: '10px' }} /></Link>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                                <button type="button" className="navbar-toggle collapsed" aria-expanded="false" onClick={navToggleClick}>
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="sidebar">
                    <Link to="/" className=""><img src="images/alshifa-logo-new-resized.png" alt="" style={{ width: "40%" }} /></Link>
                    {/* <Link to="/" className=""><img src="images/AL-SHIFA-MEDICAL-SUPPLIES-LOGO.jpg" alt="" style={{ width: "40%" }} /></Link> */}
                    <div id="toggle_close">&times;</div>
                    <div id='cssmenu'>
                        <ul>
                            {/* <li>
                                <form className="sidebar_search">
                                    <input type="search" placeholder="Search..." />
                                    <button>
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </form>
                            </li> */}
                            <li className=''>
                                <Link to="/" className={location.pathname === "/" ? "home-page menu-highlight" : "home-page"}>Home</Link>
                            </li>
                            <li className=''>
                                <Link to="/about" className={location.pathname === "/about" ? "about-page menu-highlight" : "about-page"}>About</Link>
                            </li>
                            <li className=''>
                                <Link to="/services" className={location.pathname === "/services" ? "services-page menu-highlight" : "services-page"}>Services</Link>
                            </li>
                            <li className=''>
                                <Link to="/contact" className={location.pathname === "/contact" ? "contact-page menu-highlight" : "contact-page"}>contact</Link>
                            </li>
                            {/* <li className=''>
                                <Link to="/add-user" className="">Add User</Link>
                            </li> */}

                        </ul>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default Header;