import React from "react";
import { Link } from 'react-router-dom';

const Footer = () => {

    return (

        <div className="footer" style={{ paddingBottom: "0px !important" }}>

            {/* <div className="container">
                <div className="footer_wrapper_first">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-12 col-sm-6">
                            <div className="wrapper_first_image">
                                <img src="images/AL-SHIFA-LOGO-BLACK-BLUE-SHADE.png" className="img-responsive" alt="footer3_logo_img" style={{ width: "157px", height: "100px" }} />
                            </div>
                        </div>
                        <div className="col-sm-6 visible-sm">
                            <div className="wrapper_first_icon">
                                <i className="fa fa-map-marker"></i><span>Get Appointment</span>
                            </div>
                            <div className="wrapper_first_icon">
                                <i className="fa fa-phone"></i><span>Contact our Office</span>
                            </div>
                            <div className="wrapper_first_icon">
                                <i className="fa fa-rss" aria-hidden="true"></i><span>Latest News New</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xs-12 hidden-sm">
                            <div className="wrapper_first_icon">
                                <i className="fa fa-map-marker"></i><span>Get Appointment</span>
                            </div>
                            <div className="wrapper_first_icon">
                                <span>+971 50 580 3883</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xs-12 hidden-sm">
                            <div className="wrapper_first_icon">
                                <i className="fa fa-phone"></i><span>Contact our Office</span>
                            </div>
                            <div className="wrapper_first_icon">
                                <span>+971 2 674 3006</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xs-12 hidden-sm">
                            <div className="wrapper_first_icon">
                                <i className="fa fa-rss" aria-hidden="true"></i><span>Latest News New</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
            <div className="footer_wrapper_second">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6">
                            <div className="wrapper_second_about">
                                <h4>About Us</h4>
                                <div className="abotus_content">
                                    <p className="content-color">Al Shifa Medical Supplies endeavors to be the organization that best comprehends and fulfills the products and services of the medical supplies industry.</p>
                                </div>
                                <Link to="/about" className="about-page"><div className="aboutus_link content-color">Read More<i className="fa fa-caret-right" aria-hidden="true"></i>
                                </div></Link>
                                <ul className="aboutus_social_icons">
                                    <li><i className="fa fa-facebook"></i>
                                    </li>
                                    <li><i className="fa fa-twitter"></i>
                                    </li>
                                    <li><i className="fa fa-youtube-play" aria-hidden="true"></i>
                                    </li>
                                    <li><i className="fa fa-instagram" aria-hidden="true"></i></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6">
                            <div className="wrapper_second_useful">
                                <h4>Links</h4>
                                <ul className="content-color">
                                    <Link to="/" className="home-page">
                                        <li style={{ color: 'white' }}><i className="fa fa-caret-right" aria-hidden="true"></i> Home
                                        </li>
                                    </Link>
                                    <Link to="/about" className="about-page">
                                        <li style={{ color: 'white' }}><i className="fa fa-caret-right" aria-hidden="true"></i> About
                                        </li>
                                    </Link>
                                    <Link to="/services" className="services-page">
                                        <li style={{ color: 'white' }}><i className="fa fa-caret-right" aria-hidden="true"></i> Services
                                        </li>
                                    </Link>
                                    <Link to="/contact" className="contact-page">
                                        <li style={{ color: 'white' }}><i className="fa fa-caret-right" aria-hidden="true"></i> Contact
                                        </li>
                                    </Link>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6">
                            <div className="wrapper_second_blog">
                                <h4>From the Blog</h4>
                                <div className="blog_wrapper1">
                                    <div className="blog_image">
                                        <img src="images/home/home-2/blog-img1.png" className="img-responsive"
                                            alt="blog-img1_img" />
                                    </div>
                                    <div className="blog_text">
                                        <h5 className="content-color">Last Booking Registration Date</h5>
                                        <div className="blog_date content-color"><i className="fa fa-calendar-o" aria-hidden="true"></i>June 28,
                                            2022-23</div>
                                    </div>
                                </div>
                                <div className="blog_wrapper2">
                                    <div className="blog_image">
                                        <img src="images/home/home-2/blog-img2.png" className="img-responsive"
                                            alt="blog-img2_img" />
                                    </div>
                                    <div className="blog_text">
                                        <h5 className="content-color">Learn Every Thing you Want</h5>
                                        <div className="blog_date content-color"><i className="fa fa-calendar-o" aria-hidden="true"></i>June 28,
                                            2022-23</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6">
                            <div className="wrapper_second_contact">
                                <h4>Contact Us</h4>
                                <ul>
                                    <li>
                                        <i className="fa fa-map-marker"></i>
                                        <p className="content-color">Al Ain Tower <br />Office No. 404<br /> Hamdan Street
                                            <br />Abu Dhabi UAE.
                                        </p>
                                    </li>
                                    <li><i className="fa fa-phone"></i>
                                        <p className="content-color">+971 50 580 3883
                                            <br />+971 2 674 3006
                                        </p>
                                    </li>
                                    <li className="content-color">
                                        <i className="fa fa-envelope"></i>
                                        &nbsp; &nbsp; &nbsp; info@alshifamedicalsupplies.ae
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div className="copyright_content">
                                <p className="content-color">© Copyright 2023-24 by AL SHIFA MEDICAL SUPPLIES LLC OPC - all right reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );

}

export default Footer;